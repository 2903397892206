import model from './model';
import { initOrderState } from './orderState';
import { getOrderInfo, getOrderInfoWithoutCommon } from '../../api/orderClient';
import {
  getOrderDetails,
  getOrderDetailsWithoutCommon,
} from './orderDetails/orderDetails';
import { selectors, states } from './selectors';
import { OrderDetails } from './utils/types';
import { oloLiveSiteTrackerPageLoaded } from '@wix/bi-logger-restaurants/v2';
import {
  TrackerFulfillmentType,
  TrackerPageLoadedParams,
} from '@wix/restaurants-bi';
import { orderNormalizer } from '@wix/restaurants-normalizers';
import { EcomOrderStatus } from '@wix/restaurants-types';
import { UNKNOWN } from '../../constants/orderStatusState';
import { getOrderStatus } from '../../utils/utils';
import { Specs } from '../../constants/specs';

export default model.createController(
  ({ $bind, $w, $widget, initState, flowAPI }) => {
    const orderId =
      flowAPI.controllerConfig?.wixCodeApi?.location?.query?.orderId;
    const t = flowAPI.translations.t;

    const logger = flowAPI.bi;

    const { orderInfo, updateOrderInfo } = initOrderState(initState);
    const experiments = flowAPI.experiments;
    const isUseCommonLogicEnable = experiments.enabled(Specs.USE_COMMON_LOGIC);
    const isCollapseMapEnable = experiments.enabled(Specs.COLLAPSE_MAP);

    return {
      pageReady: () => {
        if (isCollapseMapEnable) {
          $w(selectors.RestaurantDetailsBox).collapse();
        } else {
          $w(selectors.RestaurantDetailsBox).hide();
        }
        $bind(selectors.errorStateText, {
          text: () => t('tracker.error.text'),
        });

        if (flowAPI.environment.isEditor || flowAPI.environment.isPreview) {
          $w(selectors.stateBox).changeState(states.ready);
          return;
        }
        if (!orderId) {
          $w(selectors.stateBox).changeState(states.error);
        } else {
          $w(selectors.stateBox).changeState(states.loader);
          if (!isUseCommonLogicEnable) {
            getOrderInfoWithoutCommon(flowAPI.essentials.httpClient, orderId)
              .then(async (order) => {
                const orderStatus = getOrderStatus(
                  order.fulfillmentStatusesAggregate?.statuses?.at(0),
                  order.status,
                );
                $w(selectors.trackerWidget).setStatus(orderStatus);
                await $w(selectors.trackerWidget).initDuplexer(order.id);

                updateOrderInfo({
                  orderNumber: order.number,
                  orderDetails: getOrderDetailsWithoutCommon(order),
                  method: order.shippingInfo?.logistics?.pickupDetails
                    ? 'pickup'
                    : 'shipping',
                });
                $w(selectors.stateBox).changeState(states.ready);

                if (logger) {
                  const { from, to } =
                    order.shippingInfo?.logistics?.deliveryTimeSlot ?? {};
                  const fulfilledTimeFrom = from?.getTime();
                  const fulfilledTimeTo = to?.getTime();
                  const eventParams: TrackerPageLoadedParams = {
                    orderId: order.id ?? undefined,
                    orderStatus:
                      orderStatus === UNKNOWN ? undefined : orderStatus,
                    fulfilledTimeFrom,
                    fulfilledTimeTo:
                      fulfilledTimeTo && fulfilledTimeFrom !== fulfilledTimeTo
                        ? fulfilledTimeTo
                        : undefined,
                    fulfillmentType: order.shippingInfo?.logistics
                      ?.pickupDetails
                      ? TrackerFulfillmentType.PICKUP
                      : TrackerFulfillmentType.DELIVERY,
                  };
                  logger.report(oloLiveSiteTrackerPageLoaded(eventParams));
                }
              })
              .catch(() => {
                $w(selectors.stateBox).changeState(states.error);
              });
          } else {
            getOrderInfo(flowAPI.essentials.httpClient, orderId)
              .then(async (order) => {
                const normalizedOrder = orderNormalizer({
                  order,
                  options: {
                    defaultContactName: '',
                  },
                });

                const {
                  restaurantsOrderStatus,
                  isCanceled,
                  isPickupOrder,
                  fulfillmentDateTimeFrom: from,
                  fulfillmentDateTimeTo: to,
                } = normalizedOrder;
                const orderStatus = isCanceled
                  ? EcomOrderStatus.CANCELED
                  : restaurantsOrderStatus;

                $w(selectors.trackerWidget).setStatus(orderStatus);
                await $w(selectors.trackerWidget).initDuplexer(order.id);

                updateOrderInfo({
                  orderNumber: order.number,
                  orderDetails: getOrderDetails(
                    normalizedOrder,
                    flowAPI.controllerConfig.wixCodeApi.site.regionalSettings,
                  ),
                  method: isPickupOrder ? 'pickup' : 'shipping',
                });
                $w(selectors.stateBox).changeState(states.ready);

                if (logger) {
                  const fromTime = from.getTime();
                  const toTime = to.getTime();
                  const fulfilledTimeTo =
                    fromTime !== toTime ? toTime : undefined;
                  const fulfillmentType = isPickupOrder
                    ? TrackerFulfillmentType.PICKUP
                    : TrackerFulfillmentType.DELIVERY;

                  const eventParams: TrackerPageLoadedParams = {
                    orderId: order.id,
                    orderStatus,
                    fulfilledTimeFrom: fromTime,
                    fulfilledTimeTo,
                    fulfillmentType,
                  };

                  logger.report(oloLiveSiteTrackerPageLoaded(eventParams));
                }
              })
              .catch(() => {
                $w(selectors.stateBox).changeState(states.error);
              });
          }
        }
        $bind(selectors.title, { text: () => t('tracker.page.title') });
        $bind(selectors.orderNumberLabel, {
          text: () => t('tracker.page.order-number.label'),
        });
        $bind(selectors.orderNumber, { text: () => orderInfo.orderNumber });
        $bind(selectors.methodDetailsRepeater, {
          data: () => orderInfo.orderDetails,
        });
        $bind(selectors.methodDetailsTitle, {
          text: () =>
            orderInfo.method === 'pickup'
              ? t('tracker.page.pickup-details.title')
              : t('tracker.page.delivery-details.title'),
        });
        $w(selectors.methodDetailsRepeater).onItemReady(
          ($item, itemData: OrderDetails) => {
            $item(selectors.orderDetailsItemTitle).text = t(itemData.titleKey);
            $item(selectors.orderDetailsItemValue).text = itemData.value;
          },
        );

        $widget.fireEvent('widgetLoaded', {});
      },
      exports: {},
    };
  },
);
