import {
  Order as OrderProto,
  Address,
} from '@wix/ambassador-ecom-v1-order/types';
import { OrderDetails } from '../utils/types';
import { NormalizedOrder } from '@wix/restaurants-normalizers';

const getAddress = (address: Address | undefined) => {
  const addressLine = [
    address?.addressLine,
    address?.city,
    [address?.subdivision, address?.postalCode]
      .filter((addressItem) => !!addressItem)
      .join(' '),
    address?.countryFullname,
  ];

  return addressLine.filter((addressItem) => !!addressItem).join(', ');
};

const getDueBy = (from?: Date, to?: Date, regionalLocale?: string): string => {
  const dateConfig: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    month: '2-digit',
    day: '2-digit',
  };
  const timeConfig: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h24',
  };
  const isRange = from && to && from.getTime() !== to.getTime();

  if (isRange) {
    return `${from.toLocaleDateString(
      regionalLocale,
      dateConfig,
    )}, ${from.toLocaleTimeString(
      regionalLocale,
      timeConfig,
    )} - ${to.toLocaleTimeString(regionalLocale, timeConfig)}`;
  }

  return `${(from ?? to)!.toLocaleDateString(
    regionalLocale,
    dateConfig,
  )}, ${(from ?? to)!.toLocaleTimeString(regionalLocale, timeConfig)}`;
};

export function getOrderDetailsWithoutCommon(
  order: OrderProto,
): OrderDetails[] {
  const orderDetails: OrderDetails[] = [];
  const deliveryDetails = order.shippingInfo?.logistics?.shippingDestination;
  const pickupDetails = order.shippingInfo?.logistics?.pickupDetails;
  const pickupInstruction = pickupDetails
    ? order.shippingInfo?.logistics?.instructions
    : undefined;

  if (deliveryDetails) {
    const address = getAddress(deliveryDetails.address);

    if (address) {
      orderDetails.push({
        _id: `${orderDetails.length}`,
        titleKey: 'tracker.page.delivery-details.address.title',
        value: address,
      });
    }
  } else if (pickupDetails) {
    const address = getAddress(pickupDetails.address);

    if (address) {
      orderDetails.push({
        _id: `${orderDetails.length}`,
        titleKey: 'tracker.page.pickup-details.address.title',
        value: address,
      });
    }
  }

  const { from, to } = order.shippingInfo?.logistics?.deliveryTimeSlot ?? {};
  if (from || to) {
    const dueBy = getDueBy(from, to);
    orderDetails.push({
      _id: `${orderDetails.length}`,
      titleKey: pickupDetails
        ? 'tracker.page.pickup-details.due-by.title'
        : 'tracker.page.delivery-details.due-by.title',
      value: dueBy,
    });
  }

  if (pickupInstruction) {
    orderDetails.push({
      _id: `${orderDetails.length}`,
      titleKey: 'tracker.page.pickup-details.instruction.title',
      value: pickupInstruction,
    });
  }

  const orderNote = order.buyerNote;
  if (orderNote) {
    orderDetails.push({
      _id: `${orderDetails.length}`,
      titleKey: 'tracker.page.order-details.order-note.title',
      value: orderNote,
    });
  }

  return orderDetails;
}

export function getOrderDetails(
  normalizedOrder: NormalizedOrder,
  regionalLocale?: string,
): OrderDetails[] {
  const orderDetails: OrderDetails[] = [];
  const {
    isPickupOrder,
    order,
    formattedPickupAddress,
    formattedDeliveryAddress,
    fulfillmentDateTimeFrom: from,
    fulfillmentDateTimeTo: to,
  } = normalizedOrder;
  const pickupInstruction = normalizedOrder.isPickupOrder
    ? order.shippingInfo?.logistics?.instructions
    : undefined;
  const orderNote = order.buyerNote;

  if (isPickupOrder) {
    if (formattedPickupAddress) {
      orderDetails.push({
        _id: `${orderDetails.length}`,
        titleKey: 'tracker.page.pickup-details.address.title',
        value: formattedPickupAddress,
      });
    }
  } else {
    if (formattedDeliveryAddress) {
      orderDetails.push({
        _id: `${orderDetails.length}`,
        titleKey: 'tracker.page.delivery-details.address.title',
        value: formattedDeliveryAddress,
      });
    }
  }

  if (from || to) {
    const dueBy = getDueBy(from, to, regionalLocale);
    orderDetails.push({
      _id: `${orderDetails.length}`,
      titleKey: isPickupOrder
        ? 'tracker.page.pickup-details.due-by.title'
        : 'tracker.page.delivery-details.due-by.title',
      value: dueBy,
    });
  }

  if (pickupInstruction) {
    orderDetails.push({
      _id: `${orderDetails.length}`,
      titleKey: 'tracker.page.pickup-details.instruction.title',
      value: pickupInstruction,
    });
  }

  if (orderNote) {
    orderDetails.push({
      _id: `${orderDetails.length}`,
      titleKey: 'tracker.page.order-details.order-note.title',
      value: orderNote,
    });
  }

  return orderDetails;
}
